import React from "react";
import { Link } from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import "../App.css";

import CssBaseline from "@material-ui/core/CssBaseline";
import { Button, makeStyles, Toolbar, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },

  title: {
    flexGrow: 1,
  },
  div:{

    paddingLeft:theme.spacing(109)
  }
}));

export default function NavBar() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          <Link to="/" className="heading">
            <Typography variant="h6" className={classes.title}>
              NFT Finance Marketplace
            </Typography>
          </Link>
          <div  className={classes.div} >
            <Link to="/view">
              <Button>View</Button>
            </Link>
            <Link to="/create">
              <Button>Create</Button>
            </Link>
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
}
