import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { nftReducer } from "./nftSlice";

export const store = configureStore({
  reducer: {
    nftReducer,
  },
  middleware: getDefaultMiddleware({
    serializableCheck: false,
    immutableCheck: false,
  }),
});
