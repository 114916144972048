import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Web3 from "web3";
import NftCollateralFinance from "../contracts/NftCollateralFinance.json";
// import axios from "axios";

export const initWeb3 = createAsyncThunk("InitWeb3", async () => {
  try {
    if (Web3.givenProvider) {
      const web3 = new Web3(Web3.givenProvider);
      await window.ethereum.request({ method: "eth_requestAccounts" });
      const networkId = await web3.eth.net.getId();

      if (networkId !== 4) {
        alert("plz select Rinkeby test network");
        return {
          web3: null,
          contract: null,
          address: null,
          allOnSale: null,
          allOnPending: null,
          feePercentage: null,
        };
      }
      const contract = new web3.eth.Contract(
        NftCollateralFinance.abi,
        NftCollateralFinance.address
      );
      const addresses = await web3.eth.getAccounts();

      let allOnSale = await contract.methods.getAllOnSale().call();
      let feePercentage = await contract.methods.fee().call();
      // let getTokenMetaData = await contract.methods.getTokenMetaData().call();
      
      return {
        web3,
        contract,
        address: addresses[0],
        allOnSale,
        feePercentage,
      };
    }
  } catch (e) {
    console.log("Error:", e);
  }
});
export const getAllOnPending = createAsyncThunk(
  "GetAllOnPending",
  async (_, thunkAPI) => {
    try {
      const { contract } = thunkAPI.getState().nftReducer;
      let allOnPending = await contract.methods.getAllOnPending().call();
      return allOnPending
    } catch (e) {
      console.log(e);
    }
  }
);

export const unListNftCollateral = createAsyncThunk(
  "UnListNftCollateral",
  async (data, thunkAPI) => {
    try {
      const { contract, address } = thunkAPI.getState().nftReducer;
      let result = await contract.methods
        .unListNftCollateral(data, 0x00)
        .send({ from: address });

      return result;
    } catch (e) {
      console.log(e);
    }
  }
);
export const listNftCollateral = createAsyncThunk(
  "ListNftCollateral",
  async (data, thunkAPI) => {
    const { contract, address } = thunkAPI.getState().nftReducer;
    console.log(`data`, data)
    let result = await contract.methods
      .listNftCollateral(
        data.nftCollateralId,
        data.tokenAmount,
        data.price,
        data.paymentPeriod,
        data.downPaymentPercentage,
        data.duration,
        data.nftCollateralContract,
        data.currencyAddress,
        0x00
      )         
      .send({ from: address });

    return { from: result.from };
  }
);
export const payDownPaymentAndFee = createAsyncThunk(
  "PayDownPaymentAndFee",
  async (data, thunkAPI) => {
    const { contract, address } = thunkAPI.getState().nftReducer;
    await contract.methods
    let result = await contract.methods
      .payDownPaymentAndFee(data.id, data.currencyAddress, (data.amount).toString(), 0x00)
      .send({ from: address });

    return { from: result.from };
  }
);
export const sendPeriodicPayment = createAsyncThunk(
  "SendPeriodicPayment",
  async (data, thunkAPI) => {
    const { contract, address } = thunkAPI.getState().nftReducer;
    let result = await contract.methods
      .sendPeriodicPayment(data.id, data.currencyAddress, data.periodicPayment,window.ethereum.selectedAddress)
      .send({ from: address });

    return { from: result.from };
  }
);
export const ownerClaimNft = createAsyncThunk(
  "OwnerClaimNft",
  async (data, thunkAPI) => {
    const { contract, address } = thunkAPI.getState().nftReducer;
    console.log(`data`, data)
    let result = await contract.methods
      .ownerClaimNft(data, 0x00)
      .send({ from: address });

    return { from: result.from };
  }
);
export const claimNft = createAsyncThunk("ClaimNft", async (data, thunkAPI) => {
  const { contract, address } = thunkAPI.getState().nftReducer;
  let result = await contract.methods
    .claimNft(data, 0x00)
    .send({ from: address });

  return { from: result.from };
});
const nftSlice = createSlice({
  name: "NftSlice",
  initialState: {
    web3: null,
    contract: null,
    address: null,
    allOnSale: null,
    feePercentage: null,
    allOnPending: null,
  },
  reducers: {
    setAccount: (state, action) => {
      state.address = action.payload;
    },
  },
  extraReducers: {
    [claimNft.fulfilled]: (state, action) => {},

    [initWeb3.fulfilled]: (state, action) => {
      state.web3 = action.payload.web3;
      state.contract = action.payload.contract;
      state.address = action.payload.address;
      state.allOnSale = action.payload.allOnSale;
      state.feePercentage = action.payload.feePercentage;
    },
    [getAllOnPending.fulfilled]: (state, action) => {
      state.allOnPending = action.payload;
    },
    [ownerClaimNft.fulfilled]: (state, action) => {},

    [sendPeriodicPayment.fulfilled]: (state, action) => {},
    [payDownPaymentAndFee.fulfilled]: (state, action) => {},
    [listNftCollateral.fulfilled]: (state, action) => {},

    [unListNftCollateral.fulfilled]: (state, action) => {},
  },
});

export const { setAccount } = nftSlice.actions;
export const nftReducer = nftSlice.reducer;
