import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { getAllOnPending ,claimNft,ownerClaimNft} from "../store/nftSlice";
import { useDispatch, useSelector } from "react-redux";

import { Link } from "react-router-dom";
import {
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Typography,
  CardActions,
  Container,
} from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  cardMedia: {
    paddingTop: "56.25%", // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  root: {
    flexGrow: 1,
  },
  inputInput: {
    color: "white",
    textDecoration: "none",
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(50)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));
export default function View() {
  const classes = useStyles();

  const dispatch = useDispatch();
  const { allOnPending, web3 } = useSelector((state) => {
    return state.nftReducer;
  });

  useEffect(() => {
    dispatch(getAllOnPending());
  }, []); // eslint-disable-line

  return (
    <>
      {allOnPending !== null ? (
        <main>
          <Container className={classes.cardGrid} maxWidth="md">
            <Grid container spacing={4}>
              {allOnPending.map((card) => (
                <Grid item key={card} xs={12} sm={6} md={4}>
                  <Card className={classes.card}>
                    <CardMedia
                      className={classes.cardMedia}
                      image="https://source.unsplash.com/random"
                      title="Image title"
                    />
                    <CardContent className={classes.cardContent}>
                      <Typography>
                        Price: {web3.utils.fromWei(card["price"], "ether")} USCT
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <Link
                        to={{
                          pathname: "/detail",
                          data: { card },
                        }}
                      >
                        <Button size="small" color="primary">
                          View
                        </Button>
                        
                      </Link>
                      <Button size="small" color="primary"
                        onClick={()=>
                          dispatch(ownerClaimNft(card["id"]))
                        }
                        >
                          Owner Claim
                          </Button>
                          <Button size="small" color="primary"
                          onClick={()=>
                            dispatch(claimNft(card["id"]))
                          }
                          >
                            Claim
                          </Button>
                    </CardActions>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Container>
        </main>
      ) : (
        <h1>Loading...</h1>
      )}
    </>
  );
}
