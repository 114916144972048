import "./App.css";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Home from "./components/Home";
import Detail from "./components/Detail";
import View from "./components/View";
import Create from "./components/Create";
import NavBar from "./components/NavBar";
function App() {
  return (
    <div className="App">
      <Router>
      <NavBar/>
        <Route exact path="/" component={Home} />
        <Route exact path="/detail" component={Detail} />
        <Route exact path="/view" component={View} />
        <Route exact path="/create" component={Create} />
      </Router>
    </div>
  );
}

export default App;
