import React,{useState} from "react";
import TextField from "@material-ui/core/TextField";
import { Button, Container, makeStyles, Typography } from "@material-ui/core";
import { listNftCollateral } from "../store/nftSlice";
import { useDispatch, useSelector } from "react-redux";
import NftCollateralFinance from "../contracts/NftCollateralFinance.json";
import { ERC1155_ABI } from "../contracts/ERC1155ABI";
const useStyles = makeStyles((theme) => ({
  spacing:{
    marginLeft:theme.spacing(4),
   },
 spacing1:{
  marginTop:theme.spacing(3),
  marginLeft:theme.spacing(4),
 } 
 
}));
export default function Create() {


  const [nftAddress,setNftAddress] = useState("") 
 
  const classes = useStyles();
  const { web3 } = useSelector((state) => {
    return state.nftReducer;
  });
  const dispatch = useDispatch();


  function handleChange(e) {
    e.preventDefault();
    const _token = new web3.eth.Contract(ERC1155_ABI, e.target.value);
    setNftAddress(_token);
  }

  return (
    <Container maxWidth="md">
              <Typography variant="h1">List Nft</Typography>
      <div>
        <TextField required id="nftCollateralId" label="Nft token Id" />
        <TextField required id="tokenAmount" className={classes.spacing} label="Nft token Amount" />
      </div>
      <div>
        <TextField required id="price" label="price in LIB token" />
        <TextField required id="paymentPeriod" className={classes.spacing} label="payment Period in min" />
      </div>
      <div>
        <TextField
          required
          id="downPaymentPercentage"
          label="downPayment %"
        />
        <TextField required id="duration" className={classes.spacing} label="duration in min" />
      </div>
      <div>
        <TextField
          required
          id="nftCollateralContract"
          label="Nft Contract Address"
          onChange={(e)=>handleChange(e)}
        />
              <select id="currenciesList">
                <option value="0xdAC17F958D2ee523a2206206994597C13D831ec7">
                  USDT
                </option>
                <option value="0x4dbcdf9b62e891a7cec5a2568c3f4faf9e8abe2b">
                  USDC
                </option>
                <option value="0x5592ec0cfb4dbc12d3ab100b257153436a1f0fea">
                  DAI
                </option>

                <option value="0x40eab1207f61504d08329a5bda9385e28f8b5886;
">
                  LIB
                </option>
              </select>
      </div>

      <Button
      className={classes.spacing1}
        onClick={() =>
          dispatch(
            listNftCollateral({
              nftCollateralId: document.getElementById("nftCollateralId").value,
              tokenAmount: 
                document.getElementById("tokenAmount").value,
              price: web3.utils.toWei(document.getElementById("price").value),
              // paymentPeriod: parseInt(document.getElementById("paymentPeriod").value)*86400,
              paymentPeriod: parseInt(document.getElementById("paymentPeriod").value)*60,
              downPaymentPercentage:
                document.getElementById("downPaymentPercentage").value,
              // duration: parseInt(document.getElementById("duration").value)*86400,
              duration: parseInt(document.getElementById("duration").value)*60,
              nftCollateralContract:
                document.getElementById("nftCollateralContract").value,
                currencyAddress: document.getElementById("currenciesList").value,
            })
          )
        }
        variant="contained"
        color="primary"
      >
        Submit
      </Button>
      <Button
          className={classes.spacing1}
              variant="contained"
              color="primary"
              onClick={() =>
                nftAddress.methods
                  .setApprovalForAll(
                    NftCollateralFinance.address,true)
                  .send({ from: window.ethereum.selectedAddress })
              }
            >
              Approve
            </Button>
            <p>Note: First Approve and then Transact </p>
    </Container>
  );
}
