import {Button, Container, TextField, Typography} from '@material-ui/core';
import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {makeStyles} from '@material-ui/core';
import {ERC20_ABI} from '../contracts/ERC20CurrencyABI';
import '../App.css';
import {
  payDownPaymentAndFee,
  unListNftCollateral,
  sendPeriodicPayment,
} from '../store/nftSlice';
import NftCollateralFinance from '../contracts/NftCollateralFinance.json';

const useStyles = makeStyles (theme => ({
  spacing: {
    marginLeft: theme.spacing (4),
  },
  spacing1: {
    marginTop: theme.spacing (3),
    marginLeft: theme.spacing (4),
  },
}));

export default function Detail (props) {
  const classes = useStyles ();
  const [amountPayed, setAmountPayed] = useState ('0');

  const dispatch = useDispatch ();
  const {web3, feePercentage, contract} = useSelector (state => {
    return state.nftReducer;
  });

  useEffect (() => {
    getAmountPayed (props.location.data.card['id']);
  }, []); //eslint-disable-line
  async function getAmountPayed (id) {
    let _amountPayed = await contract.methods.amountPayed (id).call ();
    setAmountPayed (_amountPayed);
  }
  function calculateFee (_price) {
    return parseFloat (_price) * parseFloat (feePercentage) / 100;
  }

  function calculateDownPayment (_price, _downPaymentPercentage) {
    return parseFloat (_price) * parseFloat (_downPaymentPercentage) / 100;
  }

  const [currencyToken, setCurrencyToken] = useState ('');

  function handleChange (e) {
    e.preventDefault ();
    const _token = new web3.eth.Contract (ERC20_ABI, e.target.value);
    setCurrencyToken (_token);
  }

  function calculateAmount (price) {
    let _price = web3.utils.fromWei (price, 'ether');
    let perc = parseFloat (feePercentage / 100) * parseFloat (_price);
    let a = (perc + parseFloat (_price)).toFixed (4);
    console.log (`perc`, a);
    return a;
  }

  return (
    <Container maxWidth="md">
      <Typography variant="h1">Details</Typography>
      <Typography>
        Down Payment: {props.location.data.card['downPaymentPercentage']}%
      </Typography>
      <Typography>Fee: {feePercentage}%</Typography>
      <Typography>
        Duration:{' '}
        {// parseInt(props.location.data.card["duration"]) / 86400
        (parseInt (props.location.data.card['duration']) / 60).toString ()}{' '}
        {/* days */}
        min
      </Typography>
      <Typography>
        NFT Contract Address:{' '}
        {props.location.data.card['nftCollateralContract']}
      </Typography>
      <Typography>
        NFT Id: {props.location.data.card['nftCollateralId']}
      </Typography>
      <Typography>
        Price:
        {' '}
        {web3.utils.fromWei (props.location.data.card['price'], 'ether')}
        {' '}
        Tokens
      </Typography>
      <Typography>
        Seller Address: {props.location.data.card['sellerAddress']}
      </Typography>
      <Typography>
        Amount payed:{web3.utils.fromWei (amountPayed, 'ether')} Tokens
      </Typography>
      <Typography>
        start Date:{' '}
        {props.location.data.card['startTime'] === '0'
          ? '0'
          : new Date (
              parseInt (props.location.data.card['startTime']) * 1000
            ).toLocaleString ()}
      </Typography>
      <Typography>
        Next Payment Date:{' '}
        {props.location.data.card['nextPayment'] === '0'
          ? '0'
          : new Date (
              parseInt (props.location.data.card['nextPayment']) * 1000
            ).toLocaleString ()}
      </Typography>
      <Typography>
        Payment Period:{' '}
        {// parseInt(props.location.data.card["paymentPeriod"]) / 86400
        (parseInt (props.location.data.card['paymentPeriod']) / 60).toString ()}
        {' '}
        {/* days */}
        min
      </Typography>
      <Typography>
        NFT Amount: {props.location.data.card['tokenAmount']}
      </Typography>
      <div id="container1">
        <div>
          <div className="detail-container">
            <div className="topContainer">
              <p id="currency">Select Currency</p>
              <select id="currenciesList" onChange={e => handleChange (e)}>
                <option>Select</option>
                <option value="0xdAC17F958D2ee523a2206206994597C13D831ec7">
                  USDT
                </option>
                <option value="0x4dbcdf9b62e891a7cec5a2568c3f4faf9e8abe2b">
                  USDC
                </option>
                <option value="0x5592ec0cfb4dbc12d3ab100b257153436a1f0fea">
                  DAI
                </option>

                <option value="0x40eab1207f61504d08329a5bda9385e28f8b5886">
                  LIB
                </option>
              </select>
              <Button
                variant="contained"
                color="primary"
                id="downPayment"
                onClick={() =>
                  dispatch (
                    payDownPaymentAndFee ({
                      id: props.location.data.card['id'],
                      currencyAddress: document.getElementById (
                        'currenciesList'
                      ).value,
                      amount: calculateFee (props.location.data.card['price']) +
                        calculateDownPayment (
                          props.location.data.card['price'],
                          props.location.data.card['downPaymentPercentage']
                        ),
                    })
                  )}
              >
                {' '}
                Pay DownPayment
              </Button>
            </div>

            <div className="middle-container">
              <TextField id="payment" required label="send Periodic Payment" />
              <Button
                variant="contained"
                id="sendPeriodicPayment"
                color="primary"
                onClick={() =>
                  dispatch (
                    sendPeriodicPayment ({
                      id: props.location.data.card['id'],
                      currencyAddress: document.getElementById (
                        'currenciesList'
                      ).value,
                      periodicPayment: web3.utils.toWei (
                        document.getElementById ('payment').value
                      ),
                    })
                  )}
              >
                {' '}
                Send Periodic Payment
              </Button>
            </div>

            <Button
              variant="contained"
              color="primary"
              onClick={() =>
                dispatch (unListNftCollateral (props.location.data.card['id']))}
            >
              {' '}
              Cancle Listing
            </Button>

            <Button
              className={classes.spacing}
              variant="contained"
              color="primary"
              onClick={() =>
                currencyToken.methods
                  .approve (
                    NftCollateralFinance.address,
                    web3.utils.toWei (
                      calculateAmount (props.location.data.card['price'])
                    )
                  )
                  .send ({from: window.ethereum.selectedAddress})}
            >
              {' '}
              Approve
            </Button>
          </div>
          <p>Note: First Approve and then Transact </p>
        </div>
      </div>
    </Container>
  );
}
